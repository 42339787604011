import { render, staticRenderFns } from "./ExtensionCenter.vue?vue&type=template&id=420aa259&scoped=true&"
import script from "./ExtensionCenter.vue?vue&type=script&lang=js&"
export * from "./ExtensionCenter.vue?vue&type=script&lang=js&"
import style0 from "./ExtensionCenter.vue?vue&type=style&index=0&id=420aa259&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420aa259",
  null
  
)

export default component.exports